import { Link } from 'gatsby';
import React from 'react';
import { styled } from 'styletron-react';
import Layout from '../components/layout';

const BigText = styled('h1', {
  textAlign: 'center',
  fontSize: '5rem',
  margin: '5rem 0',
  color: 'rgba(0,0,0,.3)',
  fontWeight: 'bold',
});

const SubText = styled('h2', {
  textAlign: 'center',
  fontSize: '2rem',
});

const Text = styled('p', {
  textAlign: 'center',
});

const NotFoundPage = () => (
  <Layout>
    <BigText>404</BigText>
    <SubText>a.k.a.: not found.</SubText>
    <Text>
      The page you&apos;re trying to reach does not exist. Why don&apos;t you
      read one of my 
      {' '}
      <Link to="/blog">recent posts</Link>
      {' '}
      instead?
    </Text>
  </Layout>
);

export default NotFoundPage;
